import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import useRegion from "../hooks/useRegion";

const NotFoundPage = () => {

  const { region } = useRegion();
  const getHomeLink = () => {
    switch (region.name) {
      case 'Nigeria':
        return "/ng"
      default:
        return "/"
    }
  }
  return (
    <Layout
      pageTitle="404 - Moniepoint"
      hideFooter={true}
      type={"transparent"}
    >
      <HeroContainer>
        <StaticImage
          alt="..."
          src="../images/404.png"
          className="background-image"
        />
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title">
              <Trans>notfound.hero_title</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>notfound.hero_subtitle</Trans>
            </p>
            <Link
              to={getHomeLink()}
              className="btn btn-primary not-found-button"
            >
              <Trans>notfound.hero_cta</Trans>
            </Link>

          </div>
        </div>
        <div className="bottom-box"></div>
      </HeroContainer>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: white;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  background: radial-gradient(
    60.28% 60.28% at 50% 64.94%,
    #0357ee 8.6%,
    #003185 100%
  );

  .background-image {
    position: absolute;
    mix-blend-mode: soft-light;
    bottom: 154px;
    object-fit: cover;
    opacity: 0.9;
    width: 100vw;
    min-height: 221px;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 46px 99px;
    margin: auto;
    display: flex;
    position: relative;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;
    margin-top:87px;

    .hero-title {
      font-family: "Founders Grotesk";
font-size: 64px;
font-weight: 500;
line-height: 69px;
letter-spacing: 0em;
text-align: center;
margin-bottom: 16px;
max-width: 617px;
    }

    .hero-subtitle {
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
margin-bottom: 40px;
max-width: 617px;
    }

    .not-found-button {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(15.5px);
      border: none;
      text-align: center;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 768px) {
    .hero-content-wrapper {
      padding: 136px 20px 72px;
      align-items: flex-start;
    }

    .hero-content {
      .hero-title {
        font-size: 32px;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .hero-subtitle {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;
      }

      .not-found-button {
        padding: 14.5px 24px;
      }
    }
  }
`;
